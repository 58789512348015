import { GetStaticProps } from "next";
import { HStack, VStack, Spacing } from "goi_common";

import parlors from "@/constants/parlors_detail.json";
import { BigParlor } from "@/containers/HomeContainer/components/BigParlor";
import { MissParlorGoiHeader } from "@/containers/MissParlorDetailContainer/components/MissParlorGoiHeader";
import { HEADER_HEIGHT } from "@/constants/layout";

export const getStaticProps: GetStaticProps = async () => {
  const description =
    "장례식장 비용, 주소, 오시는 길, 전화번호, 빈소 등 종합적인 정보를 제공하고있습니다. 전국 1000개 이상의 장례식장 정보를 안내해드리겠습니다.";

  return {
    props: {
      pageDescription: description,
      pageKeywords:
        "장례식장, 장례식장 정보, 장례식장 비용, 장례식장 위치, 장례식장 빈소 현황, 오시는 길",
      pageOgTitle: "전국 장례식장 정보 제공 | 전국 장례식장 정보포털",
      pageOgDescription: description,
    },
  };
};

const Home = () => {
  return (
    <VStack width="100%" alignItems="center">
      <MissParlorGoiHeader />
      <VStack
        width="100%"
        css={{
          maxWidth: "480px",
        }}
      >
        <Spacing gap={HEADER_HEIGHT} />
        <HStack
          gap="20px"
          css={{
            flexWrap: "wrap",
          }}
        >
          {parlors.slice(0, 30)?.map((p: any) => (
            <BigParlor
              href={`/place/${p.id}/family/main/`}
              key={p.id}
              id={p.id}
              src={p.images.length > 0 ? p.images[0].url : undefined}
              name={p.companyname}
              address={p.address}
              price={p.binso_average_charge}
              type={p.type}
              description={
                p.percentage ? `평균보다 ${p.percentage}% 저렴해요` : ""
              }
            />
          ))}
        </HStack>
      </VStack>
    </VStack>
  );
};

export default Home;
