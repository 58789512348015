import { css } from "@emotion/react";
import { colors, GoiLogo, HStack, responsive, VStack } from "goi_common";

import { HEADER_HEIGHT } from "@/constants/layout";

export const MissParlorGoiHeader = () => {
  return (
    <VStack css={headerCss({ isHomeViewportTop: false, isHome: false })}>
      <HStack
        css={responsive({
          width: "100%",
          height: HEADER_HEIGHT,
          padding: {
            base: "0px 20px",
            lg: "0px 120px",
          },
          maxWidth: {
            base: "480px",
            lg: "none",
          },
        })}
        justifyContent="space-between"
        alignItems="center"
      >
        <GoiLogo color="black" />
      </HStack>
    </VStack>
  );
};

const headerCss = ({
  isHomeViewportTop,
  isHome,
}: {
  isHomeViewportTop: boolean;
  isHome: boolean;
}) =>
  css({
    alignItems: "center",
    justifyContent: "center",
    margin: "0 auto",
    position: "fixed",
    width: "100%",
    top: "0",
    backgroundColor: isHomeViewportTop ? `transparent` : colors.white,
    transition: isHome ? "background-color 0.1s linear" : "none",
    zIndex: "1000",
    userSelect: "none",
  });
