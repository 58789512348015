import styled from "@emotion/styled";

import {
  colors,
  flexCentering,
  // Typography,
  Link,
  VStack,
  HStack,
  ColorIcon,
  // Badge,
} from "goi_common";

import { css } from "@emotion/react";
// import BigParlorLoader from "@/components/Parlor/BigParlorLoader";
// import numberToLocalString from "@/utils/numberToLocalString";

interface BigParlorProps {
  href: string;
  id: number;
  src?: string;
  name: string;
  address: string;
  price: number;
  type: "대학병원" | "공설" | "전문";
  description: string;
}

export const BigParlor = ({
  href,
  id,
  src,
  name,
  address,
  price,
  type,
  description,
}: BigParlorProps) => {
  return (
    <BigParlorRoot href={href}>
      <VStack
        as="figure"
        borderRadius={5}
        overflow="hidden"
        boxShadow="0 0 7px rgba(113, 120, 125, 0.2)"
      >
        <ParlorImage src={src}>
          <Type className="subtitle3 warmGray400">{type}</Type>
          <Description className="body3_r white">{description}</Description>
          {!src && <ColorIcon name="logo" color="white" size={40} />}
        </ParlorImage>
        <VStack
          as="figcaption"
          gap={5}
          padding="12px 16px 22px"
          background={colors.white}
        >
          <div className="subtitle2_b gray600 font-ellipsis">{name}</div>
          <HStack alignItems="center">
            {/* <ColorIcon name="location" color={colors.green450} size={22} /> */}
            <div className="body3_r gray600">{address}</div>
          </HStack>
          {/* <Badge typography={Typography.body3_r}>
            빈소 평균비용 {numberToLocalString(price)}원
          </Badge> */}
        </VStack>
      </VStack>
    </BigParlorRoot>
  );
};

// BigParlor.Loader = BigParlorLoader;

const BigParlorRoot = styled(Link)`
  width: 100%;

  @media (min-width: 640px) {
    width: calc(50% - 10px);
  }
`;

const ParlorImage = styled.div<{ src?: string }>`
  ${flexCentering("row")};
  ${(props) => backgroundBySrc(props.src)};

  position: relative;
  aspect-ratio: 320 / 180;
  background-size: cover;
  background-position: center;
`;

const Type = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px 10px;
  background: var(--gray900);
`;

const Description = styled.div`
  position: absolute;
  bottom: 8px;
  left: 16px;
`;

const backgroundBySrc = (src?: string) => {
  if (src)
    return css`
      background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 41.15%,
          rgba(37, 37, 37, 0.95) 100%
        ),
        url("${src}");
    `;
  return css`
    background: linear-gradient(
        180deg,
        rgba(185, 185, 185, 0) 49.48%,
        rgba(37, 37, 37, 0.95) 100%
      ),
      var(--gray100);
  `;
};
